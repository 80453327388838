*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
}

a {
	text-decoration: none;
}

button {
	border: none;
}

html {
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	min-width: 1280px;
}

body {
	font-family: 'Inter', sans-serif;
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
}

.simple-form .submit-button {
	margin-left: auto;
}

.MuiTableHead-root {
	background: #f4f4f6;
}

.MuiTable-root {
	border: 1px solid rgb(228, 228, 239);
	border-radius: 5px;
}

.MuiTableHead-root th,
.MuiTableHead-root td,
.MuiTableBody-root td {
	border-left: 1px solid rgba(224, 224, 224, 1);
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.MuiTableHead-root th.action-buttons,
.MuiTableHead-root .action-buttons td,
.MuiTableCell-root.action-buttons {
	border-left: none;
}



.logout-btn-holder {
	position: absolute;
	top: 13px;
	right: 3px;
}

.logout-btn-holder button {
	font-size: 12px;
	color: #fff;
	opacity: 0.6;
}

.loading-box {
	text-align: center;
}
